import React, { useState } from "react";
import emailjs from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";

const Careers = () => {
  const [capVal, setCapVal] = useState(null);
  const [showDescription, setShowDescription] = useState({});

  const toggleDescription = (index) => {
    setShowDescription((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_wbw24pu",
        "template_x2wjto7",
        e.target,
        "Kh5k0K1qs98wTpbZV"
      )
      .then(() => {
        alert("Message sent successfully!");
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });
  };

  const jobPositions = [
    {
      title: "CONSTRUCTION SERVICES DEPARTMENT MANAGER",
      description: (
        <p>
          The Construction Services Department Manager manages project overview,
          work schedules, project estimates and take off proposals for the firm.
          The CSD manager is responsible for motivating team members, having
          clear communication, excellent written and verbal skills, as well as
          being proficient with report preparation. The CSD manager should have
          thorough knowledge of local municipal codes, ASTM, AASHTO, and CTQP
          test standards.
          <br />
          <br />
          <ul>
            <b>Management Duties:</b>
            <li>Have clear communication and marketing skills.</li>
            <li>Be able to attend on-site meetings.</li>
            <li>
              Be able to ensure personnel are properly trained, supervised and
              equipped with the necessary tools and equipment to ensure success
              and growth both personally and professionally.
            </li>
            <li>
              Prepare proposals and take off for materials testing projects.
            </li>
            <li>
              Motivates team members to accept increasing responsibility and
              identifies employees for promotion.
            </li>
            <li>
              Deals directly with clients and prospective clients in business
              development, proposals, contracts, and/or technical matters.
            </li>
            <li>
              Prepares performance evaluations for direct staff and reviews
              performance evaluation data for others.
            </li>
            <li>
              Oversee, manage and direct construction projects with varying
              range of types and complexity.
            </li>
          </ul>
          <ul>
            <b>Qualifications:</b>
            <li>
              Deep knowledge of geotechnical engineering, soil, asphalt, and
              concrete materials engineering and testing.
            </li>
            <li>
              Deep knowledge of commercial, roadway, and earth development
              construction.
            </li>
            <li>
              Excel in a team environment and communicate clearly with all
              co-workers and clients.
            </li>
            <li>
              Knowledge of understanding of Microsoft Office including Excel and
              Word.
            </li>
            <li>
              10+ years of experience in Construction Materials Testing or
              Inspection, Construction Management, or Geotechnical Engineering,
              for which five years are in project management and supervisory
              responsibilities.
            </li>
            <li>
              Experience staffing, directing, and coordinating
              multi-disciplinary professional staff.
            </li>
            <li>
              Ability to motivate and train staff and to earn staff respect
              based on technical and managerial competence and abilities.
            </li>
          </ul>
        </p>
      ),
    },
  ];

  return (
    <section className="space" id="blog-sec">
      <div className="container">
        <div className="title-area text-center">
          <div className="shadow-title">Careers</div>
          <span className="sub-title6 justify-content-center">
            <span className="shape left">
              <span className="dots" />
            </span>
            IMT Careers
            <span className="shape right">
              <span className="dots" />
            </span>
          </span>
          <h2 className="sec-title">Open Positions</h2>
        </div>

        {jobPositions.map((job, index) => (
          <section key={index} className="mt-5" id="open-positions">
            <div className="row">
              <div className="blog-content">
                <h6>
                  While we don't have any open positions at the moment, we
                  encourage you to submit an application for future
                  opportunities.
                </h6>
                {/* <h6 className="job-title">{job.title}</h6>
                <span>Full-time</span>
                <br />
                <br />
                <button
                  className="th-btn toggle-description"
                  onClick={() => toggleDescription(index)}
                >
                  {showDescription[index]
                    ? "Hide Description"
                    : "View Description"}
                </button>

                <div
                  className="job-description"
                  style={{ display: showDescription[index] ? "block" : "none" }}
                >
                  {job.description}
                </div> */}
              </div>
            </div>
          </section>
        ))}

        <section
          className="mt-5"
          id="application-form background-image"
          style={{ backgroundImage: "url(images/bg_pattern_10.png)" }}
        >
          <div className="contact-wrap3">
            <div className="contact-form-wrap">
              <h2 className="mb-4">Apply Now</h2>
              <form
                onSubmit={sendEmail}
                className="contact-form ajax-contact"
                method="post"
              >
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label className="form-label" htmlFor="firstName">
                      First Name*
                    </label>
                    <input
                      className="form-control"
                      id="firstName"
                      name="firstName"
                      required=""
                      type="text"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label" htmlFor="lastName">
                      Last Name*
                    </label>
                    <input
                      className="form-control"
                      id="lastName"
                      name="lastName"
                      required=""
                      type="text"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <label className="form-label" htmlFor="email">
                      Email Address*
                    </label>
                    <input
                      className="form-control"
                      id="email"
                      name="email"
                      required=""
                      type="email"
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label className="form-label" htmlFor="phone">
                      Phone*
                    </label>
                    <input
                      className="form-control"
                      id="phone"
                      name="phone"
                      required=""
                      type="tel"
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label className="form-label" htmlFor="location">
                      Location*
                    </label>
                    <input
                      className="form-control"
                      id="location"
                      name="location"
                      placeholder="e.g. 'Tampa, FL'"
                      required=""
                      type="text"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <label className="form-label" htmlFor="position">
                      Position*
                    </label>
                    <select
                      className="form-select"
                      id="position"
                      name="position"
                      required=""
                    >
                      <option disabled="" selected="" value="">
                        Select a Position
                      </option>
                      <option value="Open">Any</option>
                    </select>
                  </div>
                  <div className="col-md-4 mb-3">
                    <label className="required">Experience*</label>
                    <select
                      className="custom-select w-100"
                      name="experience"
                      required=""
                    >
                      <option selected="" />
                      <option value="0 Years">0 Years</option>
                      <option value="1-2 Years">0-2 Years</option>
                      <option value="3-5 Years">3-5 Years</option>
                      <option value="6-9 Years">6-9 Years</option>
                      <option value="10+ Years">10+ Years</option>
                    </select>
                  </div>
                  <div className="col-12 col-lg-4">
                    <label>How did you hear about us?</label>
                    <select className="custom-select w-100" name="hearAbout">
                      <option selected="" />
                      <option value="Ads">Ads</option>
                      <option value="Employee">Employee</option>
                      <option value="Friend">Friend</option>
                      <option value="Social Media">Social Media</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </div>
                <div className="mb-3">
                  <label className="form-label" htmlFor="additionalInfo">
                    Additional Information (optional)
                  </label>
                  <textarea
                    className="form-control"
                    id="additionalInfo"
                    name="additionalInfo"
                    aria-required="true"
                    rows={3}
                    defaultValue={""}
                  />
                </div>
                <ReCAPTCHA
                  sitekey="6LckIrcpAAAAAEKIsF1phIPq_I_s4xQDXwuK1qut"
                  onChange={(val) => setCapVal(val)}
                />
                <button
                  className="th-btn"
                  name="submit"
                  type="submit"
                  disabled={!capVal}
                >
                  Submit Application
                </button>
              </form>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};

export default Careers;
